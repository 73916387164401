import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import tr from "vuetify/src/locale/tr";

Vue.use(Vuetify);

const color1 = "#1aa2e6";
const color2 = "#031757";
const color3 = "#ff004f";

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: color1,
        secondary: color2,
        accent: color3,
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});
