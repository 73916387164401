import { MutationTree } from "vuex";
import { AuthState } from "./types";
import { jwtDecrypt } from "@/helpers/jwt";

export const mutations: MutationTree<AuthState> = {
  saveTokenData(state, data) {
    localStorage.setItem("token", data.token);

    const jwtDecodedValue = jwtDecrypt(data.token);

    // Set roles
    const roleValue = jwtDecodedValue["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    let roles: string[] = [];

    if (typeof roleValue === "string") {
      roles.push(roleValue);
    } else {
      roles = roleValue as string[];
    }

    // Cache token data
    state.data = {
      token: data.token,
      // expiration: data.expiration,
      expires: Number(jwtDecodedValue.exp),
      firstName: jwtDecodedValue["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
      lastName: jwtDecodedValue["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
      email: jwtDecodedValue["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
      roles: roles
    };
  },

  removeTokenData(state) {
    localStorage.removeItem("token");

    state.data = {
      token: "",
      expires: 0,
      firstName: "",
      lastName: "",
      email: "",
      roles: []
    };
  },

  setLoginStatus(state, value) {
    state.isUserLoggedIn = value;
  }
};
