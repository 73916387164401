


































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { AuthData } from "@/store/auth/types";
import { RawLocation } from "vue-router";

@Component
export default class NavBar extends Vue {
  navDrawer = false;

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  goTo(route: RawLocation) {
    this.$router.push(route).catch();
  }

  logout(): void {
    this.$store.dispatch("auth/logout");
    this.$router.push({ name: "Home" }).catch(() => {
      return false;
    });
  }

  get cartItemCount(): number {
    return this.$store.getters["cart/cartItemList"].length;
  }
}
