import { ActionTree } from "vuex";
import { CartState } from "./types";
import { RootState } from "../types";
import axios from "axios";
import store from "../index";
import { CartItem, Order } from "@/types";

export const actions: ActionTree<CartState, RootState> = {
  addItem(context, item: CartItem) {
    context.commit("addItem", item);
  },

  removeItem(context, item: CartItem) {
    context.commit("removeItem", item);
  },

  clearCart(context) {
    context.commit("setCart", []);
  },

  async saveCart(context) {
    const authData = store.getters["auth/data"];
    const authHeader = store.getters["auth/requestAuthHeader"];
    const cart = context.getters["cartItemList"];

    const res = await axios.post(
      "/api/cart/save",
      {
        username: authData.email,
        cart: cart
      },
      {
        headers: authHeader
      }
    );
  },

  async deleteCart(context) {
    const authData = store.getters["auth/data"];
    const authHeader = store.getters["auth/requestAuthHeader"];

    const res = await axios.post(`/api/cart/delete/${authData.email}`, undefined, {
      headers: authHeader
    });
  },

  async getCart(context) {
    const authData = store.getters["auth/data"];
    const authHeader = store.getters["auth/requestAuthHeader"];

    const res = await axios.get(`/api/cart/${authData.email}`, {
      headers: authHeader
    });

    context.commit("setCart", res.data);
  },

  async createOrder(context, order: Order) {
    const authHeader = store.getters["auth/requestAuthHeader"];

    const res = await axios.post(`/api/cart/create-order`, order, {
      headers: authHeader
    });

    return res.data;
  }
};
