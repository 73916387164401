import { ActionTree } from "vuex";
import { AuthState, UserProfile } from "./types";
import { RootState } from "../types";
import axios, { AxiosError } from "axios";
import store from "../index";

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, payload) {
    try {
      const res = await axios.post("/api/auth/login", {
        Username: payload.username,
        Password: payload.password
      });

      const data = res.data;

      commit("saveTokenData", data);
      commit("setLoginStatus", true);

      // Get shopping cart
      store.dispatch("cart/getCart");
    } catch (e: unknown | AxiosError) {
      if (axios.isAxiosError(e)) {
        throw new Error("Bilgileriniz doğrulanmadı. Kontrol ettikten sonra tekrar deneyiniz.");
      } else {
        throw new Error("Beklenmedik bir hata nedeniyle giriş yapılamadı.");
      }
    }
  },

  logout({ commit }) {
    commit("setLoginStatus", false);
    commit("removeTokenData");
  },

  async getUser(): Promise<UserProfile> {
    const authData = store.getters["auth/data"];
    const authHeader = store.getters["auth/requestAuthHeader"];
    const res = await axios.get(`/api/auth/get-user/${authData.email}`, {
      headers: authHeader
    });

    return res.data;
  }
};
