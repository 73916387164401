import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { auth } from "./auth/index";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { cart } from "./cart/index";

Vue.use(Vuex);

// Vuex structure based on https://codeburst.io/vuex-and-typescript-3427ba78cfa8

const store: StoreOptions<RootState> = {
  state: {
    uploadFolder: "/uploads"
  },
  modules: {
    auth,
    cart
  },
  getters,
  actions,
  mutations
};

export default new Vuex.Store<RootState>(store);
