
import Vue from "vue";
import vuetify from "./vuetify";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(VuetifyConfirm, { 
    vuetify, 
    buttonTrueText: "Evet",
    buttonFalseText: "Hayır",
    buttonTrueColor: 'primary',
    buttonFalseColor: 'grey',
    color: 'warning',
    icon: 'mdi-alert-circle'
});

export default VuetifyConfirm;
