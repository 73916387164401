import { GetterTree } from "vuex";
import { CartState } from "./types";
import { RootState } from "../types";
import { CartItem } from "@/types";

export const getters: GetterTree<CartState, RootState> = {
  cartItemList(state): CartItem[] {
    return state.cartItemList;
  },

  cartItemCount(state): number {
    return state.cartItemList.length;
  },

  cartPrice(state): number {
    let price = 0;

    state.cartItemList.map((item: any) => {
      price += item.coursePrice;
    });

    return price;
  }
};
