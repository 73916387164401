import { GetterTree } from "vuex";
import { AuthState, AuthData } from "./types";
import { RootState } from "../types";
import { isTokenValid } from "@/helpers/jwt";

export const getters: GetterTree<AuthState, RootState> = {
  data(state): AuthData {
    return state.data;
  },

  isUserLoggedIn(state): boolean {
    return state.isUserLoggedIn;
  },

  isTokenValid(state): boolean {
    if (!state.data.expires) {
      return false;
    }

    return isTokenValid(state.data.expires);
  },

  requestAuthHeader(state) {
    if (state.isUserLoggedIn) {
      return { Authorization: "Bearer " + state.data.token };
    } else {
      return {};
    }
  }
};
