import { MutationTree } from "vuex";
import { CartState } from "./types";
import { CartItem } from "@/types";

export const mutations: MutationTree<CartState> = {
  addItem(state, item: CartItem) {
    const record = state.cartItemList.find((element: any) => element.courseId == item.courseId);

    if (!record) {
      state.cartItemList.push(item);
    }
  },

  removeItem(state, item: CartItem) {
    const record = state.cartItemList.find((element: any) => element.courseId == item.courseId);

    if (record) {
      state.cartItemList.splice(state.cartItemList.indexOf(record), 1);
    }
  },

  setCart(state, cartItemList: CartItem[]) {
    if (cartItemList) {
      state.cartItemList = cartItemList;
    }
  }
};
