














import { Vue, Component } from "vue-property-decorator";
import AppHeader from "./Header.vue";
import AppFooter from "./Footer.vue";

@Component({
  components: {
    AppHeader,
    AppFooter
  }
})
export default class HomeLayout extends Vue {
  drawer = false;
}
