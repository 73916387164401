









import { Vue, Component } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Hoş geldiniz!",
    titleTemplate: "%s - ANADOLUSEM",
    meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }]
  }
})
export default class App extends Vue {}
